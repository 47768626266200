import {
    UPDATE_CUSTOMER_DETAILS as SOURCE_UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING as SOURCE_UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_LOCKED as SOURCE_UPDATE_CUSTOMER_IS_LOCKED,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL as SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS as SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS as SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS,
    updateCustomerDetails as sourceUpdateCustomerDetails,
    updateCustomerPasswordForgotEmail as sourceUpdateCustomerPasswordForgotEmail,
    updateCustomerPasswordForgotStatus as sourceUpdateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus as sourceUpdateCustomerPasswordResetStatus,
    updateCustomerSignInStatus as sourceUpdateCustomerSignInStatus,
    updateIsLoading as sourceUpdateIsLoading,
    updateIsLocked as sourceUpdateIsLocked
} from 'SourceStore/MyAccount/MyAccount.action';

// TODO: implement UPDATE_CUSTOMER_SIGN_IN_STATUS
export const UPDATE_CUSTOMER_SIGN_IN_STATUS = SOURCE_UPDATE_CUSTOMER_SIGN_IN_STATUS;

// TODO: implement UPDATE_CUSTOMER_DETAILS
export const UPDATE_CUSTOMER_DETAILS = SOURCE_UPDATE_CUSTOMER_DETAILS;

// TODO: implement UPDATE_CUSTOMER_PASSWORD_RESET_STATUS
export const UPDATE_CUSTOMER_PASSWORD_RESET_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_RESET_STATUS;

// TODO: implement UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS = SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS;

// TODO: implement UPDATE_CUSTOMER_IS_LOADING
export const UPDATE_CUSTOMER_IS_LOADING = SOURCE_UPDATE_CUSTOMER_IS_LOADING;

// TODO: implement UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL
export const UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL = SOURCE_UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL;

// TODO: implement UPDATE_CUSTOMER_IS_LOCKED
export const UPDATE_CUSTOMER_IS_LOCKED = SOURCE_UPDATE_CUSTOMER_IS_LOCKED;

export const SEND_MEMBERSHIP_DATA = 'SEND_MEMBERSHIP_DATA';

// TODO: implement updateCustomerSignInStatus
export const updateCustomerSignInStatus = sourceUpdateCustomerSignInStatus;

// TODO: implement updateCustomerDetails
export const updateCustomerDetails = sourceUpdateCustomerDetails;

// TODO: implement updateCustomerPasswordResetStatus
export const updateCustomerPasswordResetStatus = sourceUpdateCustomerPasswordResetStatus;

// TODO: implement updateCustomerPasswordForgotStatus
export const updateCustomerPasswordForgotStatus = sourceUpdateCustomerPasswordForgotStatus;

// TODO: implement updateIsLoading
export const updateIsLoading = sourceUpdateIsLoading;

// TODO: implement updateCustomerPasswordForgotEmail
export const updateCustomerPasswordForgotEmail = sourceUpdateCustomerPasswordForgotEmail;

// TODO: implement updateIsLocked
export const updateIsLocked = sourceUpdateIsLocked;

/** @namespace Frontend/Store/MyAccount/Action/sendMembershipData */
export const sendMembershipData = (fields) => ({
    type: SEND_MEMBERSHIP_DATA,
    fields
});
