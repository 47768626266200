/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import download from 'downloadjs';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import StoreInPickUpQuery from 'Query/StoreInPickUp.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay, hideActivePopup } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import { URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';
import AmastyChatQuery from '../../query/AmastyChat.query';
import AmastyViewReturnMutation from '../../query/AmastyViewReturn.mutation';
import AmastyViewReturnQuery from '../../query/AmastyViewReturn.query';
import AmastyViewReturnComponent from './AmastyViewReturn.component';
import {
    ATTRIBUTE_ELEMENT_RATING,
    CLASS_CHECKED,
    CLASS_COMPLETE,
    CLASS_DISABLED,
    CLASS_FAILED,
    ID_POPUP_INFO,
    LOGIN_URL,
    REQUEST_INCREMENT_ID_LENGTH,
    STATUS_APPROVED,
    STATUS_CANCELED_OR_REJECTED,
    STATUS_COMPLETED, STATUS_DELIVERED,
    STATUS_PROCESSING
} from './AmastyViewReturn.config';

/** @namespace Mana/AmastyRma/Route/AmastyViewReturn/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Mana/AmastyRma/Route/AmastyViewReturn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showMyPopUp: (popupKey, payload) => dispatch(showPopup(popupKey, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    hideActivePopup: () => dispatch(hideActivePopup())
});

/** @namespace Mana/AmastyRma/Route/AmastyViewReturn/Container */
export class AmastyViewReturnContainer extends PureComponent {
    clickedRating = 0;

    files=[];

    static propTypes = {
        showMyPopUp: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        hideActivePopup: PropTypes.func.isRequired
    };

    containerFunctions = {
        changeCheckboxState: this.changeCheckboxState.bind(this),
        changeCurrentRating: this.changeCurrentRating.bind(this),
        cancelRMA: this.cancelRMA.bind(this),
        submitRating: this.submitRating.bind(this),
        getProductData: this.getProductData.bind(this),
        getRequestIncrementId: this.getRequestIncrementId.bind(this),
        handleDownload: this.handleDownload.bind(this)
    };

    componentDidMount() {
        if (!isSignedIn()) {
            history.push(LOGIN_URL);
        }

        this.handleStoresSearch();
        this.loadState();
    }

    __construct(props) {
        super.__construct(props);
        this.routeParams = props.match.params;

        this.state = {
            isLoading: true,
            isRatingButtonDisabled: false,
            checkboxesTriggers: {},
            requestHash: '',
            getAmRmaSettings: {},
            getAmRmaReturnById: {},
            customer: {},
            getAmRmaRequestMessages: [],
            getAmRmaReturnInstruction: {},
            products: {},
            requestCustomFields: [],
            returnSteps: [],
            nonEmptyCustomFields: 0,
            stores: []
        };
    }

    async loadState() {
        const {
            requestHash,
            viewReturnQuery: {
                getAmRmaSettings,
                getAmRmaReturnById,
                getAmRmaReturnById: {
                    status: {
                        state: statusState
                    },
                    custom_fields
                }
            },
            orderAndMessagesQuery: {
                customer,
                getAmRmaRequestMessages,
                getAmRmaReturnInstruction
            }
        } = await this.fetchReturn(this.routeParams.requestId);

        const steps = [];

        const NotApproved = statusState === STATUS_APPROVED ? CLASS_CHECKED : '';
        const NotDelivered = statusState === STATUS_DELIVERED ? CLASS_CHECKED : '';

        if (statusState !== STATUS_CANCELED_OR_REJECTED) {
            steps[STATUS_PROCESSING] = statusState > STATUS_PROCESSING ? CLASS_COMPLETE : CLASS_CHECKED;
            steps[STATUS_APPROVED] = statusState > STATUS_APPROVED ? CLASS_COMPLETE : NotApproved;
            steps[STATUS_DELIVERED] = statusState > STATUS_DELIVERED ? CLASS_COMPLETE : NotDelivered;
            steps[STATUS_COMPLETED] = statusState === STATUS_COMPLETED ? CLASS_COMPLETE : CLASS_DISABLED;
        } else {
            steps[STATUS_PROCESSING] = CLASS_COMPLETE;
            steps[STATUS_APPROVED] = CLASS_FAILED;
            steps[STATUS_DELIVERED] = CLASS_FAILED;
            steps[STATUS_COMPLETED] = CLASS_COMPLETE;
        }

        const requestCustomFields = (custom_fields !== '') ? JSON.parse(custom_fields) : [];
        const nonEmptyCustomFields = Object.values(requestCustomFields).reduce(
            (sum, value) => (value !== '' ? sum + 1 : sum),
            0
        );

        this.setState({
            requestHash,
            getAmRmaSettings,
            getAmRmaReturnById,
            customer,
            getAmRmaRequestMessages,
            getAmRmaReturnInstruction,
            requestCustomFields,
            returnSteps: steps,
            nonEmptyCustomFields,
            ratingCommentInput: createRef(),
            isLoading: false
        });
    }

    async fetchReturn(requestId) {
        this.requestId = parseInt(requestId, 10);

        const viewReturnQuery = await fetchQuery([
            AmastyViewReturnQuery.getSettingsField(),
            AmastyViewReturnQuery.getReturnByIdField(requestId)
        ]);

        const {
            getAmRmaReturnById: {
                url_hash,
                order_increment_id,
                status: {
                    status_id
                }
            }
        } = viewReturnQuery;

        const orderAndMessagesQuery = await fetchQuery([
            AmastyViewReturnQuery.getCustomerOrderByIdField(order_increment_id),
            AmastyChatQuery.getRequestMessagesField(url_hash),
            AmastyViewReturnQuery.getReturnInstructionsField(status_id)
        ]);

        const {
            customer: {
                orders: {
                    items: [
                        { items: orderItems }
                    ]
                }
            }
        } = orderAndMessagesQuery;

        const orderProducts = orderItems ?? [];
        const skuSet = new Set(orderProducts.map(({ product_sku }) => product_sku));

        const productsDetailsQuery = await fetchQuery(AmastyViewReturnQuery.getProductsField(skuSet));
        const { products: { items: products } } = productsDetailsQuery;
        const productsQuery = {
            products
        };

        return {
            requestHash: url_hash,
            viewReturnQuery,
            orderAndMessagesQuery,
            productsQuery
        };
    }

    async handleStoresSearch() {
        try {
            const {
                getStores: {
                    stores
                } = {}
            } = await fetchQuery(StoreInPickUpQuery.getStores('IT', '', []));

            if (stores) {
                this.setState({ stores });
            }
        } catch (e) {
            this.setState({ stores: [] });
            showNotification('error', getErrorMessage(e));
        }
    }

    changeCheckboxState(e) {
        const { checkboxesTriggers } = this.state;
        this.setState({
            checkboxesTriggers: {
                ...checkboxesTriggers,
                [e.target.id]: e.target.checked
            }
        });
    }

    changeCurrentRating(e) {
        const {
            clickedRating
        } = this.state;

        const currentRating = parseInt(e.target.getAttribute(ATTRIBUTE_ELEMENT_RATING), 10);
        switch (e.type) {
        case 'click':
            this.setState({
                clickedRating: currentRating
            });
            break;

        case 'mouseenter':
            this.setState({ currentRating });
            break;

        default:
            this.setState({ currentRating: clickedRating });
            break;
        }
    }

    async cancelRMA() {
        this.setState({
            isCancelButtonDisabled: true
        });
        await fetchMutation(AmastyViewReturnMutation.cancelRMA(this.requestId));
        history.push(`/${ URL_PREFIX }/account/history`);
    }

    async submitRating() {
        const {
            showMyPopUp
        } = this.props;

        const {
            clickedRating,
            ratingCommentInput,
            requestHash
        } = this.state;

        if (clickedRating === 0) {
            this.setState({
                popupMessage: __('Please provide a rating.')
            });
            showMyPopUp(ID_POPUP_INFO);
            return;
        }

        this.setState({
            isRatingButtonDisabled: true
        });

        const input = {
            hash: requestHash,
            rating: clickedRating,
            comment: ratingCommentInput.current.value
        };
        const { rateAmRmaReturnRequest: { error } } = await fetchMutation(
            AmastyViewReturnMutation.rateReturn(input)
        );

        if (error !== false) {
            this.setState({
                popupMessage: __('An error occurred, please try again.'),
                isRatingButtonDisabled: false
            });
            showMyPopUp(ID_POPUP_INFO);
            return;
        }

        this.setState({
            isRequestRated: true
        });
    }

    getProductData({
        request_reason_id,
        request_condition_id,
        request_resolution_id,
        request_order_item_id
    }) {
        const {
            customer: {
                orders: {
                    items: [
                        { items: orderItems }
                    ]
                }
            },
            getAmRmaSettings: {
                allReasons,
                allConditions,
                allResolutions
            }
        } = this.state;

        const productSku = orderItems.reduce(
            (acc, { product_sku, amrma_order_item: { order_item_id } }) => (
                order_item_id === request_order_item_id ? product_sku : acc
            ), ''
        );

        const productImage = orderItems.reduce(
            (acc, { amrma_order_item: { order_item_id }, configurable_item }) => (
                order_item_id === request_order_item_id ? configurable_item[0].media_gallery[0].url : acc
            ), ''
        );

        const brand = orderItems.reduce(
            (acc, { amrma_order_item: { order_item_id }, configurable_item }) => (
                order_item_id === request_order_item_id ? configurable_item[0].manufacturer : acc
            ), ''
        );

        return {
            brand,
            productSku,
            productImage,
            reason: allReasons.reduce((acc, { reason_id, label: reasonLabel, payer: reasonPayer }) => {
                if (reason_id === request_reason_id) {
                    acc.reasonLabel = reasonLabel;
                    acc.reasonPayer = reasonPayer === 0
                        ? __('You should pay for Shipping.')
                        : __('Store pays for Shipping.');
                }

                return acc;
            }, { reasonLabel: '', reasonPayer: '' }),
            conditionLabel: allConditions.reduce(
                (acc, { condition_id, label }) => (condition_id === request_condition_id ? label : acc), ''
            ),
            resolutionLabel: allResolutions.reduce(
                (acc, { resolution_id, label }) => (resolution_id === request_resolution_id ? label : acc), ''
            )
        };
    }

    getRequestIncrementId(request_id) {
        return `#${request_id.toString().padStart(REQUEST_INCREMENT_ID_LENGTH, '0')}`;
    }

    handleDownload() {
        const {
            getAmRmaReturnById: {
                shipping_label,
                request_id
            }
        } = this.state;

        if (shipping_label !== null) {
            const fileUrl = appendWithStoreCode(`/media/amasty/rma/${request_id}/${shipping_label}`);
            window.open(fileUrl, '_blank');
            download(fileUrl);
        } else {
            // eslint-disable-next-line no-alert
            alert('Etichetta non disponibile');
        }
    }

    containerProps() {
        const {
            requestHash,
            customer,
            getAmRmaRequestMessages,
            getAmRmaReturnById,
            getAmRmaReturnInstruction,
            getAmRmaSettings,
            newMessageFiles,
            checkboxesTriggers,
            popupMessage,
            isLoading,
            isCancelButtonDisabled,
            isRequestRated,
            isRatingButtonDisabled,
            currentRating,
            returnSteps,
            requestCustomFields,
            nonEmptyCustomFields,
            ratingCommentInput,
            stores
        } = this.state;

        return {
            requestHash,
            customer,
            getAmRmaRequestMessages,
            getAmRmaReturnById,
            getAmRmaReturnInstruction,
            getAmRmaSettings,
            newMessageFiles,
            checkboxesTriggers,
            popupMessage,
            isLoading,
            isCancelButtonDisabled,
            isRequestRated,
            isRatingButtonDisabled,
            currentRating,
            returnSteps,
            requestCustomFields,
            nonEmptyCustomFields,
            ratingCommentInput,
            stores
        };
    }

    render() {
        return (
            <AmastyViewReturnComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyViewReturnContainer);
