/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Form from 'Component/Form';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { StoreType } from 'Type/Checkout.type';

import AmastyChat from '../../component/AmastyChat';
import AmastyCustomerInfo from '../../component/AmastyCustomerInfo';
import ReturnsCollapse from '../../component/ReturnsCollapse';
import {
    AmRmaRequestMessagesType,
    AmRmaReturnByIdType,
    AmRmaSettingsViewReturnType,
    CustomerType,
    RefType
} from '../../type/AmastyRMA.type';
import {
    ID_POPUP_INFO,
    STATUS_APPROVED, STATUS_COMPLETED, STATUS_DELIVERED, STATUS_PROCESSING
} from './AmastyViewReturn.config';

import './AmastyViewReturn.style';

/** @namespace Mana/AmastyRma/Route/AmastyViewReturn/Component */
export class AmastyViewReturnComponent extends PureComponent {
    static defaultProps = {
        popupMessage: ''
    };

    static propTypes = {
        requestHash: PropTypes.string.isRequired,
        customer: CustomerType.isRequired,
        getAmRmaRequestMessages: AmRmaRequestMessagesType.isRequired,
        getAmRmaReturnById: AmRmaReturnByIdType.isRequired,
        getAmRmaReturnInstruction: PropTypes.string.isRequired,
        getAmRmaSettings: AmRmaSettingsViewReturnType.isRequired,
        cancelRMA: PropTypes.func.isRequired,
        submitRating: PropTypes.func.isRequired,
        changeCheckboxState: PropTypes.func.isRequired,
        changeCurrentRating: PropTypes.func.isRequired,
        getRequestIncrementId: PropTypes.func.isRequired,
        getProductData: PropTypes.func.isRequired,
        checkboxesTriggers: PropTypes.objectOf(PropTypes.bool).isRequired,
        returnSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
        popupMessage: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        isCancelButtonDisabled: PropTypes.bool.isRequired,
        isRequestRated: PropTypes.bool.isRequired,
        isRatingButtonDisabled: PropTypes.bool.isRequired,
        currentRating: PropTypes.number.isRequired,
        requestCustomFields: PropTypes.objectOf(PropTypes.string).isRequired,
        nonEmptyCustomFields: PropTypes.number.isRequired,
        ratingCommentInput: RefType.isRequired,
        handleDownload: PropTypes.func.isRequired,
        stores: PropTypes.arrayOf(
            StoreType
        ).isRequired
    };

    renderProductInfo() {
        const {
            getAmRmaReturnById: {
                request_items
            }
        } = this.props;

        return (
            <div block="ProductInfoTable" elem="Container">
                <h3>{ __('Products to return') }</h3>
                <div block="ProductInfoTable" elem="HeadTable">
                    <div
                      block="ProductInfoTable"
                      elem="Element"
                      mix={ { block: 'ProductInfoTable', elem: 'ProductNameRow' } }
                    >
                        <h5>
                            { __('Product name') }
                        </h5>
                    </div>
                    <div
                      block="ProductInfoTable"
                      elem="Element"
                      mix={ { block: 'ProductInfoTable', elem: 'ProductSkuRow' } }
                    >
                        <h5>
                            { __('SKU') }
                        </h5>
                    </div>
                    <div
                      block="ProductInfoTable"
                      elem="Element"
                      mix={ { block: 'ProductInfoTable', elem: 'ProductQtyRow' } }
                    >
                        <h5>
                            { __('Qty.') }
                        </h5>
                    </div>
                    <div
                      block="ProductInfoTable"
                      elem="Element"
                      mix={ { block: 'ProductInfoTable', elem: 'ProductLabelRow' } }
                    >
                        <h5>
                            { __('Reasons for return') }
                        </h5>
                    </div>
                    <div
                      block="ProductInfoTable"
                      elem="Element"
                      mix={ { block: 'ProductInfoTable', elem: 'ProductLabelRow' } }
                    >
                        <h5>
                            { __('Product conditions') }
                        </h5>
                    </div>
                    <div
                      block="ProductInfoTable"
                      elem="Element"
                      mix={ { block: 'ProductInfoTable', elem: 'ProductLabelRow' } }
                    >
                        <h5>
                            { __('Refund method') }
                        </h5>
                    </div>
                </div>
                <div block="ProductInfoTable" elem="TableContainer">
                    <h4 className="returnLabel hiddenDesktop">{ __('Product name') }</h4>
                    { request_items.map(this.renderRequestItemBlock) }
                </div>
            </div>
        );
    }

    renderRequestItemBlock = (
        {
            reason_id: request_reason_id,
            condition_id: request_condition_id,
            resolution_id: request_resolution_id,
            product_name,
            request_qty,
            order_item_id: request_order_item_id
        }
    ) => {
        const {
            getProductData
        } = this.props;

        const {
            productSku,
            productImage,
            brand,
            reason,
            conditionLabel,
            resolutionLabel
        } = getProductData({
            request_reason_id,
            request_condition_id,
            request_resolution_id,
            request_order_item_id
        });

        return (
            // <tr className="amrma-row">
            //     <td data-th="" className="col item">
            //         <div className="amrma-image">
            //         <Image
            //           src={ productImage }
            //           mix={ {
            //               block: 'MyAccountOrderItemsTableRow',
            //               elem: 'Picture'
            //           } }
            //           ratio="square"
            //           alt={ `Product ${product_name} thumbnail.` }
            //         />
            //         </div>
            //     </td>
            //     <td data-th={ __('Product Details') } className="col amrma-details">
            //         <div className="amrma-product-information">
            //             <strong className="amrma-product-name">
            //                 { product_name }
            //                 { brand }
            //             </strong>
            //             <p className="_nomargin">
            //                 <strong>
            //                     { __('SKU') }
            //                     :
            //                     { ' ' }
            //                 </strong>
            //                 { productSku }
            //             </p>
            //             <p className="_nomargin">
            //                 <strong>
            //                     { __('Qty') }
            //                     :
            //                     { ' ' }
            //                 </strong>
            //                 { request_qty }
            //             </p>
            //         </div>
            //     </td>
            //     <td data-th={ __('RMA Details') } className="col details">
            //         <p className="_nomargin">
            //             <strong>
            //                 { __('Return Reason') }
            //                 :
            //                 { ' ' }
            //             </strong>
            //             { reason.reasonLabel }
            //         </p>
            //         <p className="_nomargin">
            //             <strong>
            //                 { __('Items Conditions') }
            //                 :
            //                 { ' ' }
            //             </strong>
            //             { conditionLabel }
            //         </p>
            //         <p className="_nomargin">
            //             <strong>
            //                 { __('Return Resolution') }
            //                 :
            //                 { ' ' }
            //             </strong>
            //             { resolutionLabel }
            //         </p>
            //         <br />
            //         <p className="_nomargin">
            //             <strong>
            //                 { reason.reasonPayer }
            //             </strong>
            //         </p>
            //     </td>
            //     <td data-th={ __('Status') } className="col status">
            //         <span className={ `amrma-status -${item_status.toLowerCase()}` }>
            //             { item_status }
            //         </span>
            //     </td>
            // </tr>
            <div block="ProductInfoTable" elem="ProductRow">
                <div
                  block="ProductInfoTable"
                  elem="Element"
                  mix={ { block: 'ProductInfoTable', elem: 'ProductNameRow' } }
                >
                    <div className="image">
                        <Image
                          src={ productImage }
                          mix={ {
                              block: 'MyAccountOrderItemsTableRow',
                              elem: 'Picture'
                          } }
                          ratio="square"
                          alt={ `Product ${product_name} thumbnail.` }
                        />
                    </div>
                    <div className="info">
                        <h5>{ brand }</h5>
                        <p>{ product_name }</p>
                    </div>
                </div>
                <div
                  block="ProductInfoTable"
                  elem="Element"
                  mix={ { block: 'ProductInfoTable', elem: 'ProductSkuRow' } }
                >
                    <h5 className="returnLabel hiddenDesktop">{ __('SKU') }</h5>
                    { productSku }
                </div>
                <div
                  block="ProductInfoTable"
                  elem="Element"
                  mix={ { block: 'ProductInfoTable', elem: 'ProductQtyRow' } }
                >
                        <h5 className="returnLabel hiddenDesktop">{ __('Qty.') }</h5>
                        { request_qty }
                </div>
                <div
                  block="ProductInfoTable"
                  elem="Element"
                  mix={ { block: 'ProductInfoTable', elem: 'ProductLabelRow' } }
                >
                    <h5 className="returnLabel hiddenDesktop">{ __('Reasons for return') }</h5>
                    { reason.reasonLabel }
                </div>
                <div
                  block="ProductInfoTable"
                  elem="Element"
                  mix={ { block: 'ProductInfoTable', elem: 'ProductLabelRow' } }
                >
                    <h5 className="returnLabel hiddenDesktop">{ __('Product conditions') }</h5>
                    { conditionLabel }
                </div>
                <div
                  block="ProductInfoTable"
                  elem="Element"
                  mix={ { block: 'ProductInfoTable', elem: 'ProductLabelRow' } }
                >
                    <h5 className="returnLabel hiddenDesktop">{ __('Refund method') }</h5>
                    { resolutionLabel }
                </div>
            </div>
        );
    };

    renderContent() {
        const {
            getAmRmaReturnById: {
                request_id,
                modReso
            },
            getRequestIncrementId,
            stores
        } = this.props;

        const storeCode = modReso !== null ? modReso.split('_')[1] : null;
        const store = storeCode !== null ? stores.find((store) => store.pickup_location_code === storeCode) : null;
        const storeName = store !== null && store !== undefined ? store.name : null;
        /* eslint-disable-next-line max-len */
        const modRma = modReso !== null && storeName !== null ? `${__('Returned in store')}: ${storeName}` : __('Courier shipment');

        return (
            <div className="amrma-main-container">
                <div className="amrma-header-block">
                    <div className="amrma-return-number">
                        <h1 className="amrma-header">
                            { __('Return request ') }
                            { getRequestIncrementId(request_id) }
                        </h1>
                        { /* <span className="amrma-status" style={ { backgroundColor: statusColor } }>{ statusLabel }</span> da riattivare in caso si volesse lo status in più oltre  quello della tab */ }
                    </div>
                </div>
                <div className="bottom-header-block">
                    <div className="amrma-action-block">
                        { this.renderIsCancelable() }
                        { this.renderPrintReturnLabel() }
                    </div>
                    { this.renderRequestStatusBlock() }
                </div>
                <div className="returns-collaps">
                    <ReturnsCollapse />
                </div>
                { this.renderFeedbackBlock() }
                { this.renderCustomerInfo() }
                { this.renderCustomFieldsBlock() }
                { /* <div className="amrma-table-container">
                    <h2 className="amrma-title">{ __('Products to return') }</h2>
                    <div className="table-wrapper amrma-items-table">
                        <table className="data table table-returns-items amrma-table">
                            <caption className="table-caption">{ __('Products to return') }</caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="col shipping">{ __('Product Details') }</th>
                                    <th scope="col" className="col id">{ __('Return Details') }</th>
                                    <th scope="col" className="col status">{ __('Item Status') }</th>
                                </tr>
                            </thead>
                            <tbody className="amrma-tbody">
                                { request_items.map(this.renderRequestItemBlock) }
                            </tbody>
                        </table>
                    </div>
                </div> */ }
                { this.renderProductInfo() }
                <div block="ReturnShippingMode">
                        <h4>{ __('Return method') }</h4>
                        <p>{ modRma }</p>
                </div>
                { this.renderChatBlock() }
                { this.renderPopup() }
            </div>
        );
    }

    renderRequestStatusBlock() {
        const {
            getAmRmaReturnById: {
                status: {
                    state: statusState
                }
            },
            returnSteps
        } = this.props;

        return (
            <div className="amrma-status-container">
                <div className="amrma-progress">
                    <div className={ `amrma-percent -status-${statusState}` } />
                </div>
                <div className="amrma-steps">
                    <div
                      className={ `amrma-step -pending ${returnSteps[STATUS_PROCESSING]}` }
                      id="0"
                      data-number="1"
                      data-label={ `${__('Processing')}` }
                    >
                        <span className="amrma-name">
                            1.
                            { ' ' }
                            { __('Processing') }
                        </span>
                    </div>
                    <div
                      className={ `amrma-step -authorized ${returnSteps[STATUS_APPROVED]}` }
                      id="1"
                      data-number="2"
                      data-label={ `${__('Approved')}` }
                    >
                        <span className="amrma-name">
                            2.
                            { ' ' }
                            { __('Approved') }
                        </span>
                    </div>
                    <div
                      className={ `amrma-step -received ${returnSteps[STATUS_DELIVERED]}` }
                      id="2"
                      data-number="3"
                      data-label={ `${__('Delivered')}` }
                    >
                        <span className="amrma-name">
                            3.
                            { ' ' }
                            { __('Delivered') }
                        </span>
                    </div>
                    <div
                      className={ `amrma-step -resolved ${returnSteps[STATUS_COMPLETED]}` }
                      id="3"
                      data-number="4"
                      data-label={ `${__('Completed')}` }
                    >
                        <span className="amrma-name">
                            4.
                            { ' ' }
                            { __('Completed') }
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderCustomerInfo() {
        const {
            getAmRmaReturnById: {
                order_increment_id,
                created_at,
                status
            },
            customer
        } = this.props;

        return (
            <AmastyCustomerInfo
              isNewReturn={ false }
              orderIncrementId={ order_increment_id }
              returnCreatedAt={ created_at }
              customer={ customer }
              status={ status }
            />
        );
    }

    renderPrintReturnLabel() {
        const {
            getAmRmaReturnById: {
                status: {
                    state: statusState
                },
                tracking_numbers
            },
            handleDownload
        } = this.props;

        if (statusState === 0) {
            return null;
        }

        const numero_track = tracking_numbers.length > 0 ? tracking_numbers[0].tracking_number : __('Not available');

        if (tracking_numbers.length <= 0) {
            return (
                <div className="amrma-shipping-label">
                    <p>
                        N.Tracking:
                        { ' ' }
                        { numero_track }
                        { ' ' }
                    </p>
                </div>
            );
        }

        return (
            <div className="amrma-shipping-label">
                <p>
                    N.Tracking:
                    { ' ' }
                    { numero_track }
                    { ' ' }
                </p>
                <button
                  className="link-shipping-label"
                  onClick={ handleDownload }
                >
                    { __('Print return label') }
                </button>
            </div>
        );
    }

    renderIsCancelable() {
        const {
            getAmRmaReturnById: {
                status: {
                    state: statusState
                }
            },
            isCancelButtonDisabled,
            cancelRMA
        } = this.props;

        if (statusState !== 0) {
            return null;
        }

        return (
            <button
              block="Button"
              className="amrma-cancel"
              onClick={ cancelRMA }
              disabled={ isCancelButtonDisabled }
            >
                { __('Cancel return request') }
            </button>
        );
    }

    renderFeedbackBlock() {
        const {
            getAmRmaSettings: {
                isAskForFeedback

            },
            getAmRmaReturnById: {
                rating,
                status: {
                    state: statusState
                },
                url_hash
            },
            submitRating,
            isRequestRated,
            isRatingButtonDisabled,
            ratingCommentInput
        } = this.props;

        if (
            isAskForFeedback
            && !rating
            && statusState === STATUS_COMPLETED
            && isRequestRated !== true
        ) {
            return (
                <div>
                    <div className="amrma-stars-container">
                        <Form onSubmit={ submitRating }>
                            <input type="hidden" name="hash" value={ url_hash } />
                            <div className="amrma-rating-block">
                                <p className="amrma-title">{ __('How do you rate the work of a manager?') }</p>
                                <div className="stars-container">
                                    { Array.from({ length: 5 }, this.renderStarsBlock) }
                                </div>
                            </div>
                            <div className="amrma-feedback-container">
                                <input
                                  ref={ ratingCommentInput }
                                  type="text"
                                  className="amrma-feedback"
                                  name="rating_comment"
                                  placeholder={ __('You can also leave a comment.') }
                                />
                                <button
                                  block="Button"
                                  title={ __('Send') }
                                  type="submit"
                                  disabled={ isRatingButtonDisabled }
                                >
                                    { __('Send') }
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            );
        }

        if (isRequestRated === true) {
            return (
                <div className="amrma-stars-container">
                    <div className="rated-message amrma-title">
                        { __('Thank you for your feedback.') }
                    </div>
                </div>
            );
        }

        return null;
    }

    renderStarsBlock = (_, i) => {
        const {
            currentRating,
            changeCurrentRating
        } = this.props;

        return (
            <input
              type="radio"
              className={ `star ${currentRating >= (i + 1) ? 'full-star' : 'hollow-star'}` }
              onMouseEnter={ changeCurrentRating }
              onMouseLeave={ changeCurrentRating }
              onClick={ changeCurrentRating }
              data-element-rating={ i + 1 }
            />
        );
    };

    renderCustomFieldsElements = ({ code, title }) => {
        const {
            requestCustomFields
        } = this.props;

        return (
            <p className="_nomargin" key={ code }>
                <span className="_strong">
                    { `${title}: ` }
                </span>
                <span className="amrma-name">{ requestCustomFields[code] }</span>
            </p>
        );
    };

    renderCustomFieldsBlock() {
        const {
            getAmRmaSettings: {
                customFields: settingsCustomFields,
                customFieldsBlockTitle
            },
            nonEmptyCustomFields
        } = this.props;

        if (nonEmptyCustomFields === 0) {
            return '';
        }

        return (
            <div className="amrma-bank-details">
                <h2 className="amrma-title">
                    { customFieldsBlockTitle }
                </h2>
                { settingsCustomFields.map(this.renderCustomFieldsElements) }
            </div>
        );
    }

    renderReturnInstructionsBlock() {
        const {
            getAmRmaReturnInstruction = '',
            changeCheckboxState,
            checkboxesTriggers
        } = this.props;
        const triggerId = 'amrma-instruction-block';

        if (!getAmRmaReturnInstruction?.length) {
            return null;
        }

        return (
            <div className="amrma-return-instructions">
                <input
                  type="checkbox"
                  id={ triggerId }
                  className="amrma-instructions"
                  onChange={ changeCheckboxState }
                />
                <label htmlFor={ triggerId } className="amrma-instructions-title">
                    <h2 className="amrma-title">{ __('Returns: how it works') }</h2>
                    <div className="amrma-show" />
                </label>
                <div className="amrma-instruction-block" hidden={ !checkboxesTriggers[triggerId] }>
                    <Html content={ getAmRmaReturnInstruction } />
                </div>
            </div>
        );
    }

    renderChatBlock() {
        const {
            getAmRmaRequestMessages,
            requestHash
        } = this.props;

        return (
            <AmastyChat
              messages={ getAmRmaRequestMessages }
              requestHash={ requestHash }
            />
        );
    }

    renderPopup() {
        const {
            popupMessage
        } = this.props;

        return (
            <div>
                <Popup
                  id={ ID_POPUP_INFO }
                >
                    <p style={ { whiteSpace: 'pre-wrap' } }>
                        { popupMessage }
                    </p>
                </Popup>
            </div>
        );
    }

    render() {
        const {
            isLoading
        } = this.props;

        if (isLoading) {
            return (
                <Loader isLoading />
            );
        }

        return (
            <div block="AmastyViewReturn">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyViewReturnComponent;
