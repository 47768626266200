/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';

import { URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';
import ReturnsCollapse from '../../component/ReturnsCollapse';
import {
    AmRmaOrderListType,
    AmRmaReturnsForCurrentCustomerType, OptionsType
} from '../../type/AmastyRMA.type';

import './AmastyMyReturnsPage.style';

/** @namespace Mana/AmastyRma/Route/AmastyMyReturnsPage/Component */
export class AmastyMyReturnsPageComponent extends PureComponent {
    static defaultProps = {
        orderLink: ''
    };

    static propTypes = {
        getAmRmaOrderList: AmRmaOrderListType.isRequired,
        getAmRmaReturnsForCurrentCustomer: AmRmaReturnsForCurrentCustomerType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        orderLink: PropTypes.string,
        setOrderLink: PropTypes.func.isRequired,
        getRequestIncrementId: PropTypes.func.isRequired,
        ordersOptions: OptionsType.isRequired,
        getRequestQty: PropTypes.func.isRequired
    };

    renderContent() {
        return (
            <div>
                <ReturnsCollapse />
                <h2 className="HeadingNewRequest">{ __('New return request') }</h2>
                { this.renderOrderSelectBlock() }
                { this.renderReturnsBlock() }
            </div>
        );
    }

    renderNoOrdersMessage() {
        return (
            <div>
                <div className="message info empty">
                    { __('You have no returnable orders yet.') }
                </div>
            </div>
        );
    }

    renderOrderSelectBlock() {
        const {
            orderLink,
            setOrderLink,
            ordersOptions
        } = this.props;

        if (!ordersOptions?.length) {
            return this.renderNoOrdersMessage();
        }

        return (
            <div className="amreturns-order-select">
                <div className="amreturns-title-container">
                    <span className="amreturns-select-title">
                        { __('Create new return request') }
                    </span>
                </div>
                <div className="amreturns-orders-container">
                    <Field
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'amreturn-order',
                          name: 'amreturns-orders-select',
                          defaultValue: ordersOptions[0].value,
                          noPlaceholder: true
                      } }
                      options={ ordersOptions }
                      events={ {
                          onChange: setOrderLink
                      } }
                      className="amreturn-select"
                    />
                    <Link
                      to={ orderLink }
                      block="Button"
                      className="btn amreturn-btn"
                      title={ __('Apply') }
                    >
                        { __('Apply') }
                    </Link>
                </div>
            </div>
        );
    }

    renderNoReturnsMessage() {
        return (
            <div key={ 1 } className="message info empty">
                { __('You have placed no return requests.') }
            </div>
        );
    }

    renderReturnsBlock() {
        const {
            getAmRmaOrderList,
            getAmRmaReturnsForCurrentCustomer
        } = this.props;

        if (!getAmRmaOrderList?.length) {
            return null;
        }

        if (!getAmRmaReturnsForCurrentCustomer?.length) {
            return this.renderNoReturnsMessage();
        }

        return (
            <div className="amrma-table-container">
                <div className="table-wrapper returns-history">
                    <table className="data table table-returns-items history amrma-table" id="my-returns-table">
                        <caption className="table-caption">
                            { __('Returns list') }
                        </caption>
                        <thead>
                            <tr>
                                <th className="col id">{ __('ID Return') }</th>
                                <th className="col total">{ __('ID Order') }</th>
                                <th className="col status">{ __('Status') }</th>
                                <th className="col shipping">{ __('Qty. items') }</th>
                                <th className="col date">{ __('Modified at') }</th>
                                <th className="col date">{ __('Created at') }</th>
                            </tr>
                        </thead>
                        <tbody className="amrma-tbody">
                            { getAmRmaReturnsForCurrentCustomer.map(this.renderReturnsTableBody) }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    renderReturnsTableBody = ({
        request_id,
        created_at,
        modified_at,
        order_increment_id,
        request_items,
        status: {
            title: statusLabel
        }
    }) => {
        const {
            getRequestQty,
            getRequestIncrementId
        } = this.props;

        const newCreated = new Date(created_at);
        const newCreated_at = newCreated.toLocaleDateString();
        const newModified = new Date(modified_at);
        const newModified_at = newModified.toLocaleDateString();

        return (
            <tr key={ request_id } className="amrma-row">
                <td data-th={ __('Id') } className="col id">
                    <span className="orderLabel">
                    { __('ID Return') }
                    </span>
                    { getRequestIncrementId(request_id) }
                </td>
                <td data-th={ __('Order #') } className="col order">
                    <span className="orderLabel">{ __('ID Order') }</span>
                    { order_increment_id }
                </td>
                <td data-th={ __('Status') } className="col status">
                    <span className="orderLabel">{ __('Status') }</span>
                    <span className="amrma-status -pending">
                        { __(statusLabel) }
                    </span>
                </td>
                <td data-th={ __('Qty') } className="col qty">
                    <span className="orderLabel">{ __('Qty. items') }</span>
                    { getRequestQty(request_items) }
                </td>
                <td data-th={ __('Date') } className="col date">
                    <span className="orderLabel">{ __('Modified at') }</span>
                    { newModified_at }
                </td>
                <td data-th={ __('Date') } className="col date">
                    <span className="orderLabel">{ __('Created at') }</span>
                    { newCreated_at }
                </td>
                <td className="col actions">
                    <Link
                      to={ `/${URL_PREFIX}/account/view/request/${request_id}` }
                      className="action view"
                      title={ __('View') }
                    >
                        { __('View return') }
                    </Link>
                </td>
            </tr>
        );
    };

    render() {
        const {
            isLoading,
            getAmRmaOrderList
        } = this.props;

        if (getAmRmaOrderList === undefined) {
            return (
                <div block="AmastyMyReturnsPage">
                    <ReturnsCollapse />
                    { this.renderNoOrdersMessage() }
                </div>
            );
        }

        if (isLoading) {
            return (
                <Loader isLoading />
            );
        }

        return (
            <div block="AmastyMyReturnsPage">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyMyReturnsPageComponent;
