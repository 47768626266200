import PropTypes from 'prop-types';

import ClickOutside from 'Component/ClickOutside';
import { MEMBERSHIP_ID } from 'Component/Popup/Popup.config';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Frontend/Component/Popup/Component */
export class Popup extends SourcePopup {
    static propTypes = {
        ...SourcePopup.propTypes,
        id: PropTypes.string.isRequired
    };

    onVisible() {
        const { onVisible, id } = this.props;

        if (id !== MEMBERSHIP_ID) {
            this.freezeScroll();
        }

        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            `${location.pathname}${location.search}${location.hash}`
        );

        onVisible();
    }

    renderContent() {
        const { children, contentMix, id } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        if (id === MEMBERSHIP_ID) {
            return (
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    { this.renderNotifications() }
                    { children }
                </div>
            );
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }
}

export default Popup;
