import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.style';

/** @namespace Frontend/Component/Loader/Component */
export class Loader extends SourceLoader {
    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                    <circle cx="50" cy="50" r="40" strokeWidth="10" strokeDasharray="188.4" strokeDashoffset="0" strokeLinecap="round" />
                </svg>
            </div>
        );
    }
}

export default Loader;
